import React from 'react';

import ShowSearchForm from '../ShowSearchForm';
import DescriptionData from '../DescriptionData';
import Facilities from '../Facilities';


let descShow = false;

export default class VenueFilters extends React.Component {

	componentDidMount(){
		$(".venue_filters").sticky();
	}

	onItemClick() {
		event.preventDefault()
		if (descShow === false) {
			let item = document.getElementById('hide-desc');
			item.style.display = 'flex';
	    descShow = true;
	  } else if (descShow === true) {
			let item = document.getElementById('hide-desc');
			item.style.display = 'none';
			descShow = false;
	  }
	}

	OnFilterClick() {
		event.preventDefault()
		if (descShow === false) {
			let item = document.getElementById('hide-filter');
			item.style.display = 'flex';
			descShow = true;
		} else if (descShow === true) {
			let item = document.getElementById('hide-filter');
			item.style.display = 'none';
			descShow = false;
		}
	}



  render () {
    return (
      <div id="expand-venue-filters" className="venue_filters">
				<div className="flex-row">
					<div id="venue_filters">
						<div>
							<h1 className="name">{ venueData.plantName }<button onClick={this.onItemClick} className="read-more"></button></h1>
							<p className="place">
								{`${venueAddress.street} ${venueAddress.city.name}`}
							</p>
						</div>
					</div>
					<ShowSearchForm />
						<div className="row">
							<div className="ok">
							<p className="form_notice">Ange antal personer och datum för din bokning</p>
							</div>
						</div>
				</div>
				<div id="hide-desc">
					<DescriptionData />
						<div className="upp-button">
							<button onClick={this.onItemClick} className="up-arrow-button"><em className="arrow-up-image"></em></button>
						</div>
				</div>
				<div className="hide-filter-wrap">
					<div id="hide-filter">
						<div className="facilities-position">
							<Facilities />
						</div>
							<div className="upp-button">
								<button onClick={this.OnFilterClick} className="up-arrow-button"><em className="arrow-up-image"></em></button>
							</div>
					</div>
				</div>
      </div>

    );
  }
}
