import React from 'react';

let filterShow = false;


export default class DescriptionData extends React.Component {

	onItemClick() {
			event.preventDefault()
			if (filterShow === false) {
				let item = document.getElementById('hide-desc');
				item.style.display = 'flex';
				filterShow = true;
			} else if (filterShow === true) {
				let item = document.getElementById('hide-desc');
				item.style.display = 'none';
				filterShow = false;
			}
		}

  render () {
    return (
			<div className="venue_descr v_info">
				<div id="description_data">
					<p className="desc-text">{ venueData.plantFacts }</p>
				</div>
			</div>
    );
  }
}
