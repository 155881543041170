import React from 'react';
import Facilities from '../Facilities';

let filterShow = false;


export default class FilterPicker extends React.Component {

	onItemClick() {
		event.preventDefault()
		if (filterShow === false) {
			let item = document.getElementById('hide-filter');
			item.style.display = 'flex';
			filterShow = true;
		} else if (filterShow === true) {
			let item = document.getElementById('hide-filter');
			item.style.display = 'none';
			filterShow = false;
		}
	}
  render () {
    return (
      <div className="filter-box">
				<button onClick={this.onItemClick} className="filter-button">Filter</button>
      </div>
    );
  }
}
