import React from 'react';

export default class RoomsAdditionalSeatings extends React.Component {

	state = {
		tags: venueData.seats
	}

	renderItem(item){
		return (
			<div>
				<li className="facilities-items" key={item.id}><img className="icons-seatings" src={ item.logo } />{ item.name }</li>
			</div>
		);
	}

  render () {
		if(!this.state.tags.length){
			return (<div></div>);
		}

    return (
      <div className="facility">
						<ul>
							<li className="filter-header"><span className="filter-name">{ lang.venueSeatings }</span></li>
							{ this.state.tags.map(this.renderItem) }
						</ul>
      </div>
    );
  }
}
