import React from 'react'

import MainSlider from '../MainSlider';
import VenueFilters from '../VenueFilters';
import DescriptionData from '../DescriptionData';
import Facilities from '../Facilities';
import OptionsNearby from '../OptionsNearby';
import VenueMap from '../VenueMap';

export default class Venue extends React.Component {
  render () {
    return (
      <div className="venue">
        <MainSlider />
				<VenueFilters />
				<div className="container">
					<div className="row">

						<div className="row">
							<div id="venue_rooms">
							</div>
						</div>
						<div class="room-wrapper"></div>
						<div className="col-sm-5 col-sm-offset-0 col-md-5 col-md-offset-0 col-xs-12 col-xs-offset-0 venue_filters_wrap" >
							<div className="venue_info venue_about">
								{/* <Facilities /> */}
									<div className="read-more">
										<button type="button" className="btn btn-success btn-xs">Mer information</button>
									</div>
							</div>
						</div>
						<div className="row">
							<VenueMap />
						</div>
						<div id="add-settings-width" className="row">
							<OptionsNearby
								venue={ 2 }
								/>
						</div>
					</div>
				</div>
      </div>
    );
  }
}
