import React from 'react';

import RoomsAdditionalSeatings from '../RoomsAdditionalSeatings';
import RoomsAdditionalFacilities from '../RoomsAdditionalFacilities';
import FacilityBeverage from '../FacilityBeverage';

let filterShow = false;

export default class Facilities extends React.Component {

	componentDidMount(){

	}

	onItemClick() {
			event.preventDefault()
			if (filterShow === false) {
				let item = document.getElementById('hide-filter');
				item.style.display = 'flex';
				filterShow = true;
			} else if (filterShow === true) {
				let item = document.getElementById('hide-filter');
				item.style.display = 'none';
				filterShow = false;
			}
		}

  render () {
    return (
			<div clasName="fixed-facilities">
				<RoomsAdditionalSeatings />
				<RoomsAdditionalFacilities />
			</div>
    );
  }
}
