import React from 'react';


export default class FacilityBeverage extends React.Component {

	state = {
		tags: venueData.plantFoodBeverages
	}

  renderListItem(item){
    return (
			<div>
				<li className="facilities-items" key={`food_${ item.id }`}><div className="circle"></div>{ item.name }</li>
			</div>
		);
  }
  render () {

		if(this.state.length == 0){
			return (<div></div>);
		}

    return (
      <div className="facility">
		        <ul>
							<li className="filter-header"><span className="filter-name">{ lang.venueFoodBeverage }</span></li>
		          {this.state.tags.map(this.renderListItem)}
		        </ul>
			</div>
    );
  }
}
